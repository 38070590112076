<template>
  <div v-if="mainData">
    <KycCustomer
      :is-show-download-button="isShowDownloadButton"
      :is-show-view-button="false"
      :main-data="mainData"
    />
    <KycLinks
      :links-custom="mainDataKeys.map(item=>({key:item, color:'secondary', short:convertCamelCaseToCapitalizedSpaces(item)}))"
      :main-data="mainData"
      is-total-grey
    />

    <KycCheck
      v-for="section in kucSectionsList"
      :key="section.key"
      :description="section.description"
      :link="section.key"
      :lists="section.lists"
      :main-data="mainData"
      :title="section.full"
      @onChange="initData"
    />
  </div>
</template>
<script>
import { mapActions, mapGetters } from 'vuex'
import waitRequest from '@/mixins/waitRequest'
import KycCustomer from '@/views/apps/kyc/components/customer.vue'
import KycLinks from '@/views/apps/kyc/components/links.vue'
import { kucSections, kycData } from '@/views/apps/kyc/config/kycTypes'
import { formattedDate } from '@/tools/util'
import checkErrorsAlert from '@/mixins/checkErrorsAlert'
import KycCheck from '@/views/apps/kyc/components/check.vue'

export default {
  name: 'KYCId',
  components: {
    KycCustomer,
    KycLinks,
    KycCheck,
  },
  mixins: [waitRequest, checkErrorsAlert],
  data() {
    return {
      kycData,
      formattedDate,
      kucSections,
      id: this.$route.params.id,
      mainData: null,
    }
  },
  computed: {
    ...mapGetters({
      listOwnerPermissions: 'users/listOwnerPermissions',
    }),
    isShowDownloadButton() {
      return this.listOwnerPermissions.includes('kyc-download-pdf') && this.mainData?.status === 'verified'
    },
    mainDataKeys() {
      return Object.keys(this.mainData).filter(key => this.mainData[key] && typeof this.mainData[key] === 'object')
    },
    kucSectionsList() {
      return this.mainDataKeys.map(key => {
        const target = this.mainData[key]

        const getFormattedValue = (value, name) => {
          if (name.includes('tedAt')) return this.formattedDate(value, kycData)
          if (['type', 'verificationLevel'].includes(name)) return this.convertCamelCaseToCapitalizedSpaces(value)
          if (['occupation', 'status'].includes(name)) return this.convertSnakeCaseToCapitalizedSpaces(value)
          return value
        }

        // const lists = []
        const lists = Object.entries(target).reduce((array, [k, v]) => {
          if (k === 'files') {
            if (!v.length) return array
            array.push({
              list: {
                files: v,
                type: key,
              },
              type: 'files',
            })
          } else {
            array[0].list.push(
              {
                title: this.convertCamelCaseToCapitalizedSpaces(k),
                value: getFormattedValue(v, k),
                type: 'default',
              },
            )
          }

          return array
        }, [{
          list: [],
          type: 'default',
        }])

        return {
          full: this.convertCamelCaseToCapitalizedSpaces(key),
          key,
          lists,
        }
      })
    },

  },
  mounted() {
    this.initData()
  },
  methods: {
    ...mapActions({
      fetchInfo: 'kyc/fetchInfoDetail',
    }),
    convertCamelCaseToCapitalizedSpaces(str) {
      return typeof str === 'string' ? str.replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase()) : str
    },
    convertSnakeCaseToCapitalizedSpaces(str) {
      return typeof str === 'string' ? str.replace(/_/g, ' ').replace(/([A-Z])/g, ' $1').toLowerCase().replace(/^\w/, char => char.toUpperCase()) : str
    },
    initData() {
      return this.waitRequest(async () => {
        const { data } = await this.fetchInfo(this.id).catch(this.checkErrorsAlert)
        if (data) this.mainData = data
      })
    },
  },
}
</script>
